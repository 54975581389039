<template>
    <div>
        <dd-card
            class="box-card"
            :title="stepTitle"
            :disabled="steps.three.disabled"
            :collapsed.sync="steps.three.collapsed"
            @toggledCard="toggledCard"
            v-loading="loading[3]"
        >
            <template v-if="hasColumnAssoc && rawCategoryAssoc.length > 0">
                <el-button :loading="isLoading" type="primary" class="fullWidth" @click="saveAssocFields"
                    >Salveaza
                </el-button>
                <div class="separator"></div>
                <div class="assoc-container">
                    <el-card
                        class="box-card assoc-card"
                        v-for="(item, index) in rawCategoryAssoc"
                        :key="index"
                        shadow="hover"
                    >
                        <category-assoc :item="item"></category-assoc>
                    </el-card>
                </div>
                <div class="separator"></div>
                <el-button :loading="isLoading" type="primary" class="fullWidth" @click="saveAssocFields"
                    >Salveaza
                </el-button>
            </template>
        </dd-card>
    </div>
</template>

<script>
const DDCard = () => import('@/components/DDCard');
const categoryAssoc = () => import('./feed.category.assoc');
import pickBy from 'lodash.pickby';
import { mapGetters, mapState } from 'vuex';
import { EventBus } from '@/utils/Bus.js';
import md5 from 'md5';
export default {
    name: 'feed-step-3',
    components: {
        'dd-card': DDCard,
        'category-assoc': categoryAssoc
    },
    data() {
        return {
            isDisabled: true
        };
    },
    methods: {
        toggledCard(status) {
            this.$store.commit('feeds/TOGGLE_COLLAPSE', {
                status,
                step: 'three'
            });
        },
        saveAssocFields() {
            const data = pickBy(this.categoryAssoc);
            if (Object.keys(data).length < 1) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Nu ati asociat nici o categorie'
                });
                return false;
            }
            this.$store
                .dispatch('feeds/SaveCategoryAssoc', {
                    assoc: data,
                    id: this.feedId
                })
                .then((res) => {
                    if (typeof res.message === 'undefined') return false;
                    this.sbMsg({
                        type: 'success',
                        message: 'Am salvat asocierea categoriilor. Puteti trece la urmatorul pas...'
                    });
                    EventBus.$emit('categAssocDone');
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'saveAssocFields',
                            params: {
                                assoc: data,
                                id: this.feedId
                            }
                        }
                    });
                });
        },
        mapSavedCategoryAssoc() {
            try {
                const savedAssoc = this.feedDetails.category_assoc;
                for (const n in savedAssoc) {
                    const index = this.rawCategoryAssoc.findIndex((item) => md5(item) === n);
                    if (index > -1) {
                        this.$store.commit('feeds/SET_CATEGORY_ASSOC', {
                            field: this.rawCategoryAssoc[index],
                            categ: savedAssoc[n]
                        });
                    }
                }
                this.$store.commit('feeds/SET_STEP_FINISHED', 'three');
            } catch (e) {
                this.sbMsg({
                    type: 'warn',
                    message: 'Asocierea categoriilor este invalida. Selectati din nou categoriile'
                });
            }
        },
        fetchCategoryAssoc() {
            this.$store.commit('feeds/SET_LOADING', {
                step: 3,
                status: true
            });
            this.$store
                .dispatch('feeds/GetColumnAssoc', {
                    id: this.feedId
                })
                .then((res) => {
                    this.$store.commit('feeds/SET_LOADING', {
                        step: 3,
                        status: false
                    });
                    if (typeof res.message !== 'object' || res.message == null) {
                        this.$store.commit('feeds/DISABLE_STEP', 'three');
                        this.$store.commit('feeds/ENABLE_STEP', 'two');

                        return false;
                    }
                    if (parseInt(res.message.status) === 0 && typeof res.message.error !== 'undefined') {
                        this.sbMsg({
                            type: 'warn',
                            message: res.message.error
                        });
                        this.$store.commit('feeds/DISABLE_STEP', 'three');
                        this.$store.commit('feeds/ENABLE_STEP', 'two');

                        return false;
                    }

                    if (res.message.data.length < 1) {
                        this.sbMsg({
                            type: 'warn',
                            message:
                                'Nu am gasit categorii pentru asociere. Verificati asociera coloanelor de la pasul 3 si incercati din nou.'
                        });
                        this.$store.commit('feeds/DISABLE_STEP', 'three');
                        this.$store.commit('feeds/ENABLE_STEP', 'two');
                        return false;
                    }
                    this.$store.commit('feeds/SET_RAW_CATEG_ASSOC', res.message.data);

                    // console.log("this.hasCategoryAssoc: ", this.hasCategoryAssoc)
                    // console.log("this.rawCategoryAssoc ", this.rawCategoryAssoc)
                    // console.log("this.feedDetails ", this.feedDetails)
                    // console.log("this.hasColumnAssoc ", this.hasColumnAssoc)

                    if (this.hasCategoryAssoc) {
                        this.mapSavedCategoryAssoc();
                    }
                })
                .catch((e) => {
                    this.$reportToSentry(e, {
                        extra: {
                            fn: 'fetchCategoryAssoc',
                            params: {
                                id: this.feedId
                            }
                        }
                    });
                    this.$store.commit('feeds/SET_LOADING', {
                        step: 3,
                        status: false
                    });
                    this.$store.commit('feeds/DISABLE_STEP', 'three');
                    this.$store.commit('feeds/ENABLE_STEP', 'two');
                });
        }
    },
    computed: {
        ...mapGetters({
            assocFields: 'feeds/assocFields',
            feedDetails: 'feeds/feedDetails',
            feedId: 'feeds/feedId',
            feedValidated: 'feeds/feedValidated',
            hasParsedFields: 'feeds/hasParsedFields',
            feedStep: 'feeds/feedStep',
            latestCompletedStep: 'feeds/latestCompletedStep',
            addProductMode: 'feeds/addProductMode',
            isLoading: 'feeds/isLoading'
        }),
        ...mapState({
            steps: (state) => state.feeds.steps,
            fieldAssocSelected: (state) => state.feeds.fieldAssocSelected,
            assocProductContainer: (state) => state.feeds.assocProductContainer,
            rawFields: (state) => state.feeds.feedRawFields,
            parsedFields: (state) => state.feeds.parsedFields,
            loading: (state) => state.feeds.loading,
            rawCategoryAssoc: (state) => state.feeds.rawCategoryAssoc,
            categoryAssoc: (state) => state.feeds.categoryAssoc
        }),
        hasColumnAssoc() {
            return this.assocFields && Object.keys(this.assocFields).length > 0;
        },
        hasCategoryAssoc() {
            return (
                typeof this.feedDetails.category_assoc !== 'undefined' &&
                this.feedDetails.category_assoc != null &&
                this.feedDetails.category_assoc !== ''
            );
        },
        latestStep() {
            return this.latestCompletedStep;
        },
        stepTitle() {
            return this.addProductMode
                ? '3. Asociere categorii'
                : '3. Asociere categorii (dezactivat in cazul modelului Actualizare pret si stoc)';
        }
    },
    watch: {
        latestStep: {
            handler(step) {
                if (this.feedStep >= 2 && step === 2 && this.addProductMode) {
                    this.$store.commit('feeds/ENABLE_STEP', 'three');
                    this.fetchCategoryAssoc();
                } else if (step < 3) {
                    this.$store.commit('feeds/DISABLE_STEP', 'three');
                    this.$store.commit('feeds/TOGGLE_COLLAPSE', {
                        status: false,
                        step: 'three'
                    });
                }
            },
            deep: true,
            immediate: true
        },
        addProductMode(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.$store.commit('feeds/DISABLE_STEP', 'three');
                this.$store.commit('feeds/TOGGLE_COLLAPSE', {
                    status: false,
                    step: 'two'
                });
            } else {
                if (this.feedStep >= 3 && this.latestStep >= 2 && this.addProductMode) {
                    this.$store.commit('feeds/ENABLE_STEP', 'three');
                }
            }
        }
    },
    created() {
        EventBus.$on('assocDone', () => {
            if (!this.addProductMode) return false;
            this.$store.commit('feeds/ENABLE_STEP', 'three');
            this.$store.commit('feeds/CLOSE_STEPTS_EXCEPT', 'three');
            this.fetchCategoryAssoc();
        });
    },
    mounted() {}
};
</script>

<style lang="scss">
@import './src/styles/modules/feeds.scss';

.assoc-card:not(:last-child) {
    margin-bottom: 30px;
}
</style>
